import axios from '@/utils/request'
import qs from 'qs'
const request = {

  getInit(params){
    return axios.post('/api/rank/init',qs.stringify(params))
  }
  // 测试组列表
};

export default request
