import axios from 'axios'
const service = axios.create({
  baseURL: window.httpUrl,
  // withCredentials: true,
  timeout: 10000 // request timeout
});

// request interceptor
service.interceptors.request.use(
  config => {
    // Do something before request is sent
    // if (localStorage.getItem('token')) {
    //   // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
    //
    //   // config.headers['AccessToken'] = getToken();
    //   config.headers["Authori-zation"] = "Bearer "+localStorage.getItem('token');
    //
    // }
    // if (store.getters.token) {
    //   // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
    //
    //   // config.headers['AccessToken'] = getToken();
    //   config.headers['RefreshToken'] = getRToken();
    //   config.headers['token'] = getToken();
    // }
    // config.headers['Content-Type']='application/json'

    return config
  },
  error => {
    // Do something with request error
    Promise.reject(error)
  }
);

// response interceptor

service.interceptors.response.use(
  response => {

    return response.data
    // }
  },
  error => {
    return Promise.reject(error)
  }
)

export default service
