import Vue from 'vue'
import App from './App.vue'
import api from '@/api';
import axios from './utils/request'
Vue.config.productionTip = false
Vue.prototype.$api = api;
Vue.prototype.$axios = axios;
new Vue({
  render: h => h(App),
}).$mount('#app')
